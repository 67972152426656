<script lang="ts">
import { testImage } from '../lib/testImage';
import { content } from '../store/content';

const handleImageButtonClick = () => content.addContent('[image:]');

const handleTextareaButtonClick = () => content.addContent('');

const handleRemove = (i: number) => {
  const newContent = $content;
  newContent.splice(i, 1);
  content.writeContent(newContent);
};

const handleRemoveAll = () => content.resetContent();

const handleWriteContent = () => {
  content.writeContent($content);
};
</script>

<div id="container">
  <div class="flex-horizontal">
    <h2>內容</h2>
    <button on:click="{handleImageButtonClick}">圖片</button>
    <button on:click="{handleTextareaButtonClick}">文字</button>
    <button on:click="{handleRemoveAll}">刪除全部</button>
    <button class="save" on:click="{handleWriteContent}">儲存</button>
  </div>
  <div id="content_container">
    {#each $content as p, i (`${p}_${i}`)}
      {#if testImage(p)}
        <div class="flex-horizontal">
          <input bind:value="{p}" />
          <button on:click="{() => handleRemove(i)}">-</button>
        </div>
      {:else}
        <div class="flex-horizontal">
          <textarea bind:value="{p}"></textarea>
          <button on:click="{() => handleRemove(i)}">-</button>
        </div>
      {/if}
    {/each}
  </div>
</div>

<style>
#container {
  max-height: 400px;
  overflow-y: scroll;
}

:global(button) {
  margin: 0;
  background-color: white;
  border: none;
  box-shadow: 0.8px 0.5px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 8px 15px;
  transition: 0.3s background-color ease;
}

.save {
  background-color: #319795;
  color: white;
}

.save:active {
  background-color: #2c7a7b;
}

button:not(:first-child) {
  margin-left: 15px;
}

#content_container {
  display: flex;
  flex-direction: column;
}

input {
  width: 80%;
}

textarea {
  width: 80%;
}
</style>
