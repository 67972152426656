<script lang="ts">
import { testImage } from '../lib/testImage';
import { parseImageUrl } from '../lib/parseImageUrl';
export let title: string;
export let coverImage: string;
export let coordinates: string[];
export let content: string[];

const coverImagePlaceholderUrl = 'https://via.placeholder.com/1';

const handleCoverImageError = (e) => (e.target.src = coverImagePlaceholderUrl);

const splitNewline = (str: string) => str.split('\n');
const flatStringArr = (strArr: string[]) => {
  return strArr.map((str) => splitNewline(str)).flat();
};
</script>

<article id="container">
  <img
    aria-placeholder="1231231"
    width="600px"
    height="450px"
    style="object-fit: contain"
    src="{coverImage || coverImagePlaceholderUrl}"
    alt="cover_iamge"
    on:error="{handleCoverImageError}" />
  <div id="content">
    <h1>{title || 'title'}</h1>
    <h3>[{coordinates[0]}, {coordinates[1]}]</h3>
    {#each flatStringArr(content) as p, i (`${p}_${i}`)}
      {#if testImage(p)}
        <img
          src="{parseImageUrl(p)}"
          alt="show_image_{p}"
          width="{'400px'}"
          height="{'300px'}"
          style="object-fit: contain" />
      {:else}
        <p>{p}</p>
      {/if}
    {/each}
  </div>
</article>

<style>
article#container {
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-left: 5rem;
  max-height: 90vh;
  overflow-y: scroll;
  border: 5px solid #333333;
  border-radius: 5px;
}
div#content {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 2rem 0;
}

h1 {
  max-width: 500px;
  word-wrap: break-word;
  word-break: break-all;
  font-size: 4rem;
  margin: 0;
}

h3 {
  margin: 0.875rem 0;
}

p {
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 35px;
  max-width: 500px;
  word-wrap: break-word;
  word-break: break-all;
  margin: 0;
}

p:not(:first-child) {
  margin-top: 2rem;
}

img {
  margin-top: 2em;
}
</style>
