<script lang="ts">
import ControlPlate from './component/ControlPlate.svelte';
import Showcase from './component/Showcase.svelte';
import { content } from './store/content';

let title: string = '';
let coordinates: [string, string] = ['0.00', '0.00'];
let coverImage: string = '';
</script>

<main>
  <ControlPlate bind:title bind:coordinates bind:coverImage />
  <Showcase bind:title bind:coordinates bind:coverImage content="{$content}" />
</main>

<style>
main {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 2rem auto;
}

:global(.flex-horizontal) {
  display: flex;
  align-items: center;
}
</style>
