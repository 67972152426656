<script lang="ts">
import App from '../App.svelte';

export let containerStyle: string = '';
export let htmlFor: string;
export let label: string = '';
export let value: string = '';
</script>

<div style="{containerStyle}">
  <label for="{htmlFor}">
    {label}
  </label>
  <input {...$$restProps} bind:value />
</div>

<style>
div {
  display: flex;
  align-items: center;
}

label {
  margin-right: 10px;
}

input {
  margin: 0;
}
</style>
