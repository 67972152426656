import { writable } from 'svelte/store';
function createContent() {
    const { subscribe, set, update } = writable([]);
    return {
        subscribe,
        addContent: (newContent) => update((arr) => {
            arr[arr.length] = newContent;
            return arr;
        }),
        resetContent: () => update((_) => []),
        writeContent: (newContent) => update((_) => newContent),
    };
}
export const content = createContent();
