<script lang="ts">
import Input from './Input.svelte';
import ContentInput from './ContentInput.svelte';
import { content } from '../store/content';

export let title: string;
export let coordinates: string[];
export let coverImage: string;

const handleButtonClick = () => {
  const data = {
    title,
    coordinates: [+coordinates[0], +coordinates[1]],
    image: coverImage,
    description: $content.join('\n'),
  };

  navigator.clipboard.writeText(JSON.stringify(data));
};
</script>

<div id="container">
  <span>
    <button on:click="{handleButtonClick}">複製</button>
  </span>
  <Input
    label="標題圖片"
    htmlFor="cover_image"
    containerStyle="margin-bottom: 1.5rem"
    bind:value="{coverImage}" />
  <Input label="標題" htmlFor="title" bind:value="{title}" />
  <div id="coordinate_container">
    <Input
      label="緯度"
      htmlFor="latitude"
      type="number"
      step=".1"
      bind:value="{coordinates[0]}" />
    <Input
      label="經度"
      htmlFor="longitude"
      type="number"
      step=".1"
      containerStyle="margin-left: 15px"
      bind:value="{coordinates[1]}" />
  </div>
  <ContentInput />
</div>

<style>
#container {
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
}

span {
  margin-bottom: 1.5rem;
}

#coordinate_container {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}
</style>
